import React from "react"
import {
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/core"
import { useForm } from "react-hook-form"
import firebase from "gatsby-plugin-firebase"
import { navigate } from "gatsby"
import { v1 as uuid } from "uuid"
import Layout from "../components/Layout"
import { H1 } from "../components/Typography"
import SEO from "../components/SEO"

export default function NewClassified() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm()
  const toast = useToast()

  function uploadImage(id, image) {
    return new Promise((resolve, reject) => {
      if (!image) resolve([null, null])
      const imageNameArr = image.name.split(".")
      const fileType = imageNameArr[imageNameArr.length - 1]
      firebase
        .storage()
        .ref()
        .child(`classifieds/${id}/image.${fileType}`)
        .put(image)
        .then(snapshot => {
          snapshot.ref.getDownloadURL().then(function (imageDownloadURL) {
            resolve([imageDownloadURL, `classifieds/${id}/image.${fileType}`])
          })
        })
        .catch(err => {
          reject([err, err])
        })
    })
  }

  async function onSubmit(data) {
    try {
      await firebase.auth().signInAnonymously()

      const { image, category, ...rest } = data

      const newCategory = {
        id: uuid(),
        name: category,
      }

      const newAd = {
        id: uuid(),
        categoryId: newCategory.id,
        date: new Date().toISOString(),
        ...rest,
      }
      const [imageDownloadURL] = await uploadImage(newAd.id, image[0])
      const batch = firebase.firestore().batch()

      const catRef = firebase
        .firestore()
        .collection("classifiedCategories")
        .doc(newCategory.id)
      batch.set(catRef, newCategory)
      const adRef = firebase.firestore().collection("classifieds").doc(newAd.id)
      batch.set(adRef, {
        ...newAd,
        featuredImageUrl: imageDownloadURL,
      })

      await batch.commit()
      toast({
        title: "Ad created.",
        description: "Your Ad has been created and will appear shortly.",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      reset()
      navigate("/classifieds")
    } catch (err) {
      console.log("err: ", err)
      toast({
        title: "Failed to create ad.",
        description:
          "We could not create your ad. Please try again or contact  us.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Layout>
      <SEO title="New Classified Ad" />
      <Flex width="100%" justify="center" align="center" direction="column">
        <H1 textAlign="center">Post New Classified Ad</H1>

        <Flex mt={[10]} width="100%" direction="column">
          <form onSubmit={handleSubmit(onSubmit)} method="post">
            <FormControl isRequired>
              <FormLabel htmlFor="title">Title</FormLabel>
              <Input
                variant="outline"
                type="text"
                id="title"
                name="title"
                ref={register({
                  required: "Title is required",
                })}
                borderColor="lightgray"
                aria-describedby="title-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="email-helper-text" color="lightgray">
                The title of this ad
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="description">Description</FormLabel>
              <Textarea
                id="description"
                name="description"
                ref={register({
                  required: "Description is required",
                })}
                multiple
                borderColor="lightgray"
                aria-describedby="description-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="description-helper-text" color="lightgray">
                Description of the item you are selling
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="price">Price</FormLabel>
              <Input
                variant="outline"
                type="number"
                id="price"
                name="price"
                ref={register({
                  required: "Price is required",
                })}
                borderColor="lightgray"
                aria-describedby="price-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="price-helper-text" color="lightgray">
                Price of the item you are selling
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="contactName">Contact Name</FormLabel>
              <Input
                variant="outline"
                type="text"
                id="contactName"
                name="contactName"
                ref={register({
                  required: "Contact Name is required",
                })}
                borderColor="lightgray"
                aria-describedby="contactName-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="contactName-helper-text" color="lightgray">
                Contact name for all enquiries of this item
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="contactNumber">Contact Number</FormLabel>
              <Input
                variant="outline"
                type="text"
                id="contactNumber"
                name="contactNumber"
                ref={register({
                  required: "Contact Number is required",
                })}
                borderColor="lightgray"
                aria-describedby="contactNumber-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="contactNumber-helper-text" color="lightgray">
                Contact number for all enquiries of this item
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="number">Location</FormLabel>
              <Input
                variant="outline"
                type="text"
                id="location"
                name="location"
                ref={register({
                  required: "Location is required",
                })}
                borderColor="lightgray"
                aria-describedby="location-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="location-helper-text" color="lightgray">
                Location of where the item is sold. e.g. Laudium, Erasmia,
                Claudius
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="image">Image/Photo</FormLabel>
              <Input
                variant="outline"
                type="file"
                id="image"
                name="image"
                ref={register({
                  required: "Image is required",
                })}
                accept="image/*"
                borderColor="lightgray"
                aria-describedby="image-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="image-helper-text" color="lightgray">
                Upload an photo of the item you are selling
              </FormHelperText>
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel htmlFor="category">Category</FormLabel>
              <Input
                variant="outline"
                type="text"
                id="category"
                name="category"
                ref={register({
                  required: "Categgory is required",
                })}
                borderColor="lightgray"
                aria-describedby="category-helper-text"
                _hover={{
                  borderColor: "lightgray",
                }}
              />
              <FormHelperText id="category-helper-text" color="lightgray">
                A category for the item you are selling. E.g. TV
              </FormHelperText>
            </FormControl>
            <Button
              marginY={10}
              p={8}
              width="100%"
              variantColor="blue"
              type="submit"
              isDisabled={isSubmitting}
            >
              Post New Ad
            </Button>
          </form>
        </Flex>
      </Flex>
    </Layout>
  )
}
